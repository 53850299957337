const colors = {
  bgPrimary: '#FFFFFF',
  bgSecondary: '#FAFAFA',
  bgAlt: '',
  colorPrimary: '#111111',
  colorSecondary: '#55595C',
  colorAlt: '#7E868C',
  btnPrimary: '#38bdf9',
  btnSecondary: '#C7EBF4',
  btnAlt: '',
  error: '#DC3444',
  warning: '#fc0',
  success: '#28A745',
  info: '#015CC8',
  black: '#000000',
  btnBg: '#0d91db',
  purple: '#6466f1',
  orange: "#ff5c00",
  lightBlack: "#121212",
  white: "#fbfbfb",
  yellow: "#ffb800",
  gray: "#D4D4D8",
  lightGray: "#A1A1AA",
  jaguar: "#27272a",
  questionElement: "#ff35a1"
}

// These margins are defined for the outer container, so everything looks aligned and follows a set rule
const spacing = {
  xxl: {
    y: 36,
    x: 16,
  },
  xl: {
    y: 32,
    x: 14,
  },
  lg: {
    y: 24,
    x: 14,
  },
  md: {
    y: 20,
    x: 12,
  },
  sm: {
    y: 16,
    x: 8,
  },
}

const sizes = {
  largeHeading: {
    h1: {
      fontSize: 120,
      lineHeight: 108,
    },
    h2: {
      fontSize: 80,
      lineHeight: 80,
    },
    h3: {
      fontSize: 64,
      lineHeight: 76,
    },
    h4: {
      fontSize: 48,
      lineHeight: 48,
    },
    h5: {
      fontSize: 32,
      lineHeight: 38,
    },
    h6: {
      fontSize: 58,
      lineHeight: 58,
    }
  },
  heading: {
    h1: {
      fontSize: 72,
      lineHeight: 70,
    },
    h2: {
      fontSize: 54,
      lineHeight: 64,
    },
    h3: {
      fontSize: 42,
      lineHeight: 50,
    },
    h4: {
      fontSize: 32,
      lineHeight: 45,
    },
    h5: {
      fontSize: 24,
      lineHeight: 32,
    },
    h6: {
      fontSize: 20,
      lineHeight: 28,
    },
  },
  content: {
    p1: {
      fontSize: 24,
      lineHeight: 32,
    },
    p2: {
      fontSize: 18,
      lineHeight: 28,
    },
    p3: {
      fontSize: 16,
      lineHeight: 32,
    },
    p4: {
      fontSize: 16,
      lineHeight: 20,
    },
    p5: {
      fontSize: 16,
      lineHeight: 24,
    },
    p6: {
      fontSize: '',
      lineHeight: '',
    },
  },
  small: {
    s1: {
      fontSize: '',
      lineHeight: '',
    },
    s2: {
      fontSize: '',
      lineHeight: '',
    },
    s3: {
      fontSize: '',
      lineHeight: '',
    },
    s4: {
      fontSize: '',
      lineHeight: '',
    },
    s5: {
      fontSize: '',
      lineHeight: '',
    },
    s6: {
      fontSize: '',
      lineHeight: '',
    },
  },
  button: {
    large: {
      fontSize: 18,
      lineHeight: 27,
      padding: '30px 50px',
    },
    medium: {
      fontSize: 16,
      lineHeight: 24,
      padding: '10px 15px',
    },
    small: {
      fontSize: 11,
      lineHeight: 16.5,
      padding: '22px 28px 22px 18px',
    },
  },
}

const buttons = {
  base: {
    borderWidth: '1px',
    borderRadius: '5px',
  },
  primaryCta: {
    background: colors.btnPrimary,
    color: colors.bgPrimary,
    borderColor: colors.btnPrimary,
  },
  secondaryCta: {
    background: 'transparent',
    color: colors.black,
    borderColor: colors.info,
  },
  default: {
    background: colors.colorSecondary,
    color: colors.bgPrimary,
    borderColor: colors.colorSecondary,
  },
  discord: {
    background: colors.purple,
    color: colors.bgPrimary,
    borderColor: colors.purple,
  },
  primaryOutline: {
    background: 'transparent',
    color: colors.btnPrimary,
    borderColor: colors.btnPrimary,
  },
  redOutline: {
    background: 'transparent',
    color: colors.error,
    borderColor: colors.error,
  },
}

export const theme = {
  sizes,
  colors,
  spacing,
  buttons,
}

export default theme
