import { SessionProvider } from 'next-auth/react'
import { Session } from 'next-auth'
import { FC, useEffect, useState } from 'react'
import fetcher from 'fetcher'
import { SWRConfig } from 'swr'
import { CNOptions, NotificationContext } from 'mad-mint/contexts/Notification'
import '../styles/global.css'
import Notifier from 'mad-mint/components/Notifier'
import AdminAuthGuard from 'mad-mint/components/Authentication/admin'
import CreatorAuthGuard from 'mad-mint/components/Authentication/creator'
import AuthGuard from 'mad-mint/components/Authentication/registered'
import Root from './Root'

type AppProps = {
  Component: FC & {
    requireAuth?: boolean
    requireAdmin?: boolean
    requireCreator?: boolean
  }
  pageProps: {
    session: Session | null
  }
}

const swrConfigOpts = {
  fetcher,
}

export default function App({
  Component,
  pageProps: { session, ...pageProps },
}: AppProps) {
  const notificationState = useState<CNOptions>(null)
  return (
    <SessionProvider session={session}>
      <SWRConfig value={swrConfigOpts}>
        <NotificationContext.Provider value={notificationState}>
          <Root>
            {Component.requireAdmin ? (
              <div className="bg-white">
                <AdminAuthGuard>
                  <Component {...pageProps} />
                </AdminAuthGuard>
              </div>
            ) : Component.requireCreator ? (
              <div className="bg-white">
                <CreatorAuthGuard>
                  <Component {...pageProps} />
                </CreatorAuthGuard>
              </div>
            ) : Component.requireAuth ? (
              <div className="bg-white">
                <AuthGuard>
                  <Component {...pageProps} />
                </AuthGuard>
              </div>
            ) : (
              <Component {...pageProps} />
            )}

            <Notifier />
          </Root>
        </NotificationContext.Provider>
      </SWRConfig>
    </SessionProvider>
  )
}
