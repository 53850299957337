import React, { ReactNode, useContext } from 'react'
import clsx from 'clsx'
import Sidebar from './Sidebar'
import MadMintThemeContext from '../contexts/MadMint'
import Spinner from './Spinner'
import { Session } from 'next-auth'

type Props = {
  children: ReactNode
  className?: string
  loading?: boolean
}

const Layout: React.FC<Props> = ({ children, className, loading }) => {
  const { theme } = useContext(MadMintThemeContext)
  return (
    <div className={clsx('madmint container mx-auto p-4', className)}>
      <div className="flex flex-col lg:flex-row flex-1">
        {loading ? (
          <div className="flex flex-1 h-screen items-center justify-center">
            <Spinner className="flex h-1/6" />
          </div>
        ) : (
          <>
            <aside className="sidebar-section w-full lg:w-60">
              <Sidebar theme={theme} />
            </aside>
            <main className="flex-1 pt-5 s">
              <section>{children}</section>
            </main>
          </>
        )}
      </div>
      <style jsx>{`
        .content {
          max-width: 1440px;
          margin: auto;
        }
        .madmint :global(button[disabled]) {
          opacity: 0.5;
          cursor: not-allowed;
        }
        .sidebar-section {
          background: ${theme.colors.colorAlt};
          color: ${theme.colors.bgSecondary};
          margin-right: 2rem;
        }
        @media screen and (max-width: 1279px) {
          .container {
            max-width: 100%;
          }
        }
        @media screen and (max-width: 1023px) {
          .sidebar-section {
            background: transparent;
            color: ${theme.colors.black};
          }
        }
      `}</style>
    </div>
  )
}

export default Layout
