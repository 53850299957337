import { getSession } from 'next-auth/react'
const track = async (name: string) => {
  const session = await getSession()
  window.postMessage(
    {
      type: 'track',
      name,
      payload: {
        user: session?.user,
      },
    },
    '*'
  )
}

export const trackSubscribe = () => track('Subscribe')
export const trackCompleteRegistration = () => track('CompleteRegistration')
