import React, { useState } from 'react'
import Link from 'next/link'
import { signIn, useSession, signOut } from 'next-auth/react'
import Icon from './Icon'
import { AnimatePresence, motion } from 'framer-motion'
import { useRouter } from 'next/router'

type Props = {
  theme: any
}

const itemVariants = {
  closed: {
    opacity: 0,
  },
  open: { opacity: 1 },
}

const sideVariants = {
  closed: {
    transition: {
      staggerChildren: 0.025,
      staggerDirection: -1,
    },
  },
  open: {
    transition: {
      staggerChildren: 0.1,
      staggerDirection: 1,
    },
  },
}

const INIT_STYLES = {
  width: 0,
  minWidth: 0,
}

const FINAL_STYLES = {
  width: '100vw',
}

const Sidebar: React.FC<Props> = ({ theme }) => {
  const { data: session, status } = useSession({
    required: true,
    onUnauthenticated() {
      signIn()
    },
  })

  const [open, setOpen] = useState(false)
  const toggleSideMenu = () => {
    setOpen(!open)
  }

  return (
    <div className="sidebar">
      {status == 'authenticated' && (
        <div className="menu desktop-menu">
          <UserMenu session={session} />
        </div>
      )}
      <div className="mobile-menu">
        <Icon icon="menu" size={30} onClick={toggleSideMenu} />
      </div>
      <div className="side-menu">
        <AnimatePresence>
          {open && (
            <motion.aside
              className="sidebar"
              initial={INIT_STYLES}
              animate={FINAL_STYLES}
              exit={{
                ...INIT_STYLES,
                transition: { delay: 0.3, duration: 0.7 },
              }}
            >
              <div className="sb-container">
                <motion.div
                  className="sidebar-header flex items-center justify-between w-full p-2.5"
                  initial="closed"
                  animate="open"
                  exit="closed"
                  variants={itemVariants}
                >
                  <motion.div
                    onClick={toggleSideMenu}
                    initial="closed"
                    animate="open"
                    exit="closed"
                    variants={itemVariants}
                    className="sb-close"
                  >
                    <Link href="/">
                      <a onClick={toggleSideMenu}>
                        <img className='w-[100px]' src="/images/legendsofrock/logo.png" alt="Logo"/>
                      </a>
                    </Link>
                  </motion.div>
                  <motion.div
                    onClick={toggleSideMenu}
                    initial="closed"
                    animate="open"
                    exit="closed"
                    variants={itemVariants}
                    className="sb-close"
                  >
                    <Icon
                      icon="cross"
                      className="cursor-pointer"
                      size={40}
                    ></Icon>
                  </motion.div>
                </motion.div>
                <motion.div
                  className="sidebar-body flex flex-col"
                  initial="closed"
                  animate="open"
                  exit="closed"
                  variants={sideVariants}
                >
                  <UserMenu session={session} />
                </motion.div>
              </div>
            </motion.aside>
          )}
        </AnimatePresence>
      </div>
      <style jsx>{`
        .side-menu {
          height: 100vh;
          position: fixed;
          z-index: 120;
          left: 0px;
          top: 0px;
        }
        .logo :global(.logo-icon) {
          margin-right: 17px;
        }
        .side-menu :global(.sidebar-header) {
          border-bottom: 1px solid;
        }
        .mobile-menu {
          display: none;
        }
        @media screen and (max-width: 1023px) {
          .mobile-menu {
            display: block;
          }
          .desktop-menu {
            display: none;
          }
        }
      `}</style>
      <style jsx>{`
        .side-menu {
          background-color: ${theme.colors.colorAlt};
          color: ${theme.colors.bgSecondary};
        }
        .side-menu :global(.sidebar-header) {
          border-color: ${theme.borderColor};
        }
      `}</style>
    </div>
  )
}

function UserMenu({ session }: { session: any }) {
  const {
    query: { slug },
  } = useRouter()
  const handleSignOut = async () => {
    signOut()
  }
  return (
    <ul className="list-none p-3">
      <li className="border-b-2 borde-white-500 py-2">
        <Link href="/profile">
          <a>Profile</a>
        </Link>
      </li>
      {session && session.user?.roles?.includes('admin') && (
        <>
          <li className="border-b-2 borde-white-500 py-2">
            <Link href="/admin/role">
              <a>Manage Roles</a>
            </Link>
          </li>
          <li className="border-b-2 borde-white-500 py-2">
            <Link href="/admin/user">
              <a>Manage Users</a>
            </Link>
          </li>
        </>
      )}

      {session &&
        (session.user?.roles?.includes('admin') ||
          session.user?.roles?.includes('creator')) && (
          <>
            <li className="border-b-2 borde-white-500 py-2">
              <Link href="/projects">
                <a>Projects</a>
              </Link>
            </li>
            {slug && (
              <li className="border-b-2 borde-white-500 py-2">
                <Link href={`/projects/${slug}/entries`}>
                  <a>Entry List</a>
                </Link>
              </li>
            )}
          </>
        )}
      
      <li className="border-b-2 borde-white-500 py-2">
        <a className="cursor-pointer" onClick={handleSignOut}>Sign Out</a>
      </li>
    </ul>
  )
}

export default Sidebar
